
import { createReducer, on } from '@ngrx/store';
import { Brands } from 'app/models/brand';
import { brandingUpdated, identityUpdated, themeUpdated } from '../actions/identity.actions';

export interface IdentityState { theme: Brands, branding: Brands }
export const initialIdentityState: IdentityState = { theme: Brands.newfound, branding: Brands.newfound };

export const identityReducer = createReducer(
  initialIdentityState,
  on(brandingUpdated, (state, { branding }) => ({ ...state, branding })),
  on(themeUpdated, (state, { theme }) => ({ ...state, theme })),
  on(identityUpdated, (state, { branding, theme }) => ({ ...state, branding, theme })),
);
