import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Brands } from 'app/models/brand';
import { brandingUpdated, identityUpdated, themeUpdated } from 'app/store/actions/identity.actions';
import { getTimelineById } from 'app/store/selectors/timeline.selectors';
import { AppState } from 'app/store/states/app.state';
import { of } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { UrlService } from './url.service';

@Injectable({
  providedIn: 'root'
})
export class IdentityService {
  constructor(
    private store: Store<AppState>,
    private urlService: UrlService,
  ) { }

  // Identity is composed of brand and theme.
  // Brand is the icons and business unit.
  // Theme is the styling of the application based on brand

  syncIdentity() {
    return this.urlService.currentUrl().pipe(
      tap(url => {
        if (this.urlService.isExternalHouwzerRoute(url)) {
          const identity = this.getIdentityFromWindowLocation();
          this.setIdentity(identity);
          return;
        }
        // we might reset the brand below in the switch map, but make sure we have one
        this.setIdentity(Brands.newfound)
      }),
      // switchMap(url => {
      //   if (this.urlService.isTimelinePage(url)) {
      //     return this.timelineBrandFromUrl(url).pipe(tap((brand: Brands) => { this.setBrand(brand) }));
      //   }

      //   return of(null);
      // }),
    )
  }

  syncDomToTheme() {
    return this.getCurrentTheme().pipe(tap((theme) => { this.setThemeSelector(theme) }),);
  }

  setBrand(brand: Brands) {
    this.store.dispatch(brandingUpdated({ branding: brand }));
  }

  setTheme(theme: Brands) {
    this.store.dispatch(themeUpdated({ theme: theme }));
  }

  setIdentity(theme: Brands, brand?: Brands) {
    this.store.dispatch(identityUpdated({ theme: theme, branding: brand ? brand : theme }));
  }

  getCurrentBranding() {
    return this.store.select('identity').pipe(map(({ branding }) => branding))
  }

  getCurrentTheme() {
    return this.store.select('identity').pipe(map(({ theme }) => theme))
  }

  getBrandFromPrimaryAgentEmailAddress(emailAddress: string) {
    const [brand] = emailAddress.split('@')[1].split('.')
    if (brand === 'trelora') return Brands.trelora;
    if (brand === 'houwzer') return Brands.houwzer;

    return Brands.newfound;
  }

  private getIdentityFromWindowLocation() {
    const fullUrl = this.urlService.fullUrl();
    if(fullUrl.match(/trelora\.com/i)) return Brands.trelora;
    // any houwzer.com/app/* urls redirect to app.newfoundgroup.com/*
    // if(fullUrl.match(/houwzer\.com/i)) return Brands.houwzer;

    return Brands.newfound;
  }

  private setThemeSelector(theme: Brands) {
    document.documentElement.setAttribute('data-theme', theme);
  }

  // this exists in its current form because:
  // cannot access routing params easily outside the context of a routing page (in app component).
  // we do not have an easy way to select the current timeline from the store.
  // if we could get the current timeline from the store without the url, we could make sync identity much cleaner
  // with a combinelatest
  private timelineBrandFromUrl(url: string) {
    const urlTimelineType = url.match('listings') ? 'listings/' : 'home-searches/'
    const [timelineId] = url.split(urlTimelineType)[1].split('/');
    if (!timelineId) {
      return of(Brands.newfound);
    }

    return getTimelineById(this.store, parseInt(timelineId)).pipe(
      map((timeline) => {
        try {
          const { agents, primary_agent_id } = timeline;
          const primaryAgent = agents.find(({ id }) => id === primary_agent_id);
          return this.getBrandFromPrimaryAgentEmailAddress(primaryAgent.email_address);
        } catch (error) {
          return Brands.newfound;
        }
      }))
  }
}
