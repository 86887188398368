import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate } from '@angular/router';
import { map } from 'rxjs/operators';
import { AppState, Store } from 'app/store/states/app.state';
import { getCurrentUser, currentUserIsAgentOrCoordinator } from 'app/store/selectors/user.selectors';
import { combineLatest } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class DotloopAuthGuard implements CanActivate {

  constructor(
    private router: Router,
    private store: Store<AppState>
  ){};

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return combineLatest([
      this.store.select(state => state.dotloop),
      getCurrentUser(this.store),
      currentUserIsAgentOrCoordinator(this.store)
    ])
    .pipe(
      map(([ dotloopState, currentUser, isAgentOrCoordinator ]) => {
        if(!currentUser) return false;

        if(!isAgentOrCoordinator) return true;

        // The presense of a dotloop state token is an indication from the API
        // that the user has not yet successfully retrieved their dotloop_authorization_code
        // and should use this state token to try and fetch one via the dotloop auth module
        if(!dotloopState || !dotloopState.state_token) return true;
        if(dotloopState.isAuthDimissed) return true;

        this.router.navigate([ 'dash', 'dotloop-authorization', 'authorize' ], { queryParams: { state: dotloopState.state_token }});
        return false;
      })
    )

  }
}
