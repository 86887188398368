import { ActionReducer, union } from '@ngrx/store';
import { UserLoginActionTypes, UserLoginSuccessAction } from '../actions/login.actions';
import { UserLogoutActions, UserLogoutActionTypes } from '../actions/logout.actions';
import { createReducer, on } from '@ngrx/store';
import { DotloopState } from '../states/dashboard.state';
import { dotloopAuthCompleted, dotloopAuthDismissed } from '../actions/dotloop.actions';

// https://medium.com/@brandontroberts/mixing-action-styles-in-ngrx-state-562332a2d799

export const initialDotloopState: DotloopState = { state_token: null, isAuthDimissed: true };

const v2DotloopReducer = createReducer(
  initialDotloopState,
  on(dotloopAuthDismissed, (state) => ({ ...state, isAuthDimissed: true })),
  on(dotloopAuthCompleted, (state) => ({...initialDotloopState})), // user authed, disable dotloop auth actions appwide
)

const CounterActionsUnion = union({dotloopAuthDismissed});
type Actions =
  | UserLoginSuccessAction
  | UserLogoutActions
  | typeof CounterActionsUnion;

export const dotloopReducer: ActionReducer<DotloopState> = (state: DotloopState= initialDotloopState, action: Actions) => {
  switch (action.type) {
    case UserLoginActionTypes.USER_LOGIN_SUCCESS:
      const { dotloop_state_token } = action?.response;
      console.log(dotloop_state_token);

      return {
        state_token: dotloop_state_token ?? null,
        isAuthDimissed: !dotloop_state_token
      }
    case UserLogoutActionTypes.USER_LOG_OUT:
      return null;
    default:
      return v2DotloopReducer(state, action);
  }
};




